import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import {
  FormattedMessage,
  FormattedHTMLMessage,
  useIntl
} from 'gatsby-plugin-intl'

import 'src/utils/string'
import {
  BOOLEAN,
  SUBSCRIPTION_TYPES,
  COMPANY_PROFILES,
  parseToOptionsTranslated,
  COUNTRIES,
  LEAD_SOURCE_OPTIONS
} from 'src/ducks/consts'
import TextInput from 'src/components/atoms/TextInput'
import Select from 'src/components/atoms/Select'
import Checkbox from 'src/components/atoms/Checkbox'
import {
  InputsRowWrapper,
  InputWrapper
} from 'src/components/atoms/FormHelpers'
import { Button } from 'src/components/atoms/Buttons'
import {
  validateSignup,
  validateSignupSchema
} from 'src/features/auth/duck/schema'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { Panel, Row, Column } from './components/atoms'

import connect from './duck/connectors'
import {
  PanelTitle,
  PanelSubtitle,
  SectionTitle,
  Condition
} from './components/typography'
import Steps from './components/Steps'

const Signup = ({
  className,
  registration,
  setRegistrationValue,
  registerUser
}) => {
  const intl = useIntl()
  const valid = useValidateSchema(registration.toJS(), validateSignupSchema)

  const leadSourceSelectValue = useSelector(
    state => state.register.leadSourceSelect
  )

  const leadSource = useSelector(state => state.register.leadSource)

  const generateLeadSourceAffiliateProgram = () => {
    const affilliateProgramSelectValue = intl.formatMessage({
      id: `signUp.leadSourceSelectOptions.${leadSourceSelectValue}`
    })
    return `${affilliateProgramSelectValue}:  ${registration.leadSourceAffiliateProgramDetailsFirstAndLastName}, ${registration.leadSourceAffiliateProgramDetailsPhone}, ${registration.leadSourceAffiliateProgramDetailsCompany}`
  }

  const [
    leadSourceOtherDetailsVisible,
    setLeadSourceOtherDetailsVisible
  ] = useState(false)

  const [
    leadSourceAffiliateProgramDetailsVisible,
    setLeadSourceAffiliateProgramDetailsVisible
  ] = useState(false)

  React.useEffect(() => {
    // dirty hacking. Don't want to mess with backend enums.. this is only frontend Select + computed leadsource value (customer written if 'other selected')

    if (leadSourceSelectValue === 'other') {
      setLeadSourceOtherDetailsVisible(true)
    } else {
      setLeadSourceOtherDetailsVisible(false)
      setRegistrationValue('leadSourceOtherDetails', '')
    }

    if (leadSourceSelectValue === 'affiliate_program') {
      setLeadSourceAffiliateProgramDetailsVisible(true)
    } else {
      setLeadSourceAffiliateProgramDetailsVisible(false)
      setRegistrationValue(
        'leadSourceAffiliateProgramDetailsFirstAndLastName',
        ''
      )
      setRegistrationValue('leadSourceAffiliateProgramDetailsPhone', '')
      setRegistrationValue('leadSourceAffiliateProgramDetailsCompany', '')
    }

    setRegistrationValue(
      'leadSource',
      intl.formatMessage({
        id: `signUp.leadSourceSelectOptions.${leadSourceSelectValue}`
      })
    )
  }, [leadSourceSelectValue])

  const handleLeadSourceOtherDetailsChange = (name, value) => {
    setRegistrationValue('leadSourceOtherDetails', value)
    setRegistrationValue('leadSource', value)
  }

  const handleLeadSourceAffiliateProgramDetailsFirstAndLastNameChange = (
    name,
    value
  ) => {
    setRegistrationValue(
      'leadSourceAffiliateProgramDetailsFirstAndLastName',
      value
    )
    setRegistrationValue('leadSource', generateLeadSourceAffiliateProgram())
  }

  const handleLeadSourceAffiliateProgramDetailsPhoneChange = (name, value) => {
    setRegistrationValue('leadSourceAffiliateProgramDetailsPhone', value)
    setRegistrationValue('leadSource', generateLeadSourceAffiliateProgram())
  }

  const handleLeadSourceAffiliateProgramDetailsCompanyChange = (
    name,
    value
  ) => {
    setRegistrationValue('leadSourceAffiliateProgramDetailsCompany', value)
    setRegistrationValue('leadSource', generateLeadSourceAffiliateProgram())
  }

  return (
    <div className={className}>
      <Panel>
        {/* LeadSource: {leadSource} */}
        <Column>
          <Steps
            activeIndex={registration.activeStepIndex}
            steps={[
              intl.formatMessage({ id: 'signUp.companyData' }),
              intl.formatMessage({ id: 'common.user' })
            ]}
            onStepClick={index =>
              setRegistrationValue('activeStepIndex', index)
            }
          />
          <PanelTitle>
            <FormattedMessage id='signUp.title' />
          </PanelTitle>
          <PanelSubtitleStyled>
            <FormattedMessage id='signUp.subtitle' />
          </PanelSubtitleStyled>
          <PanelSubtitleNoteStyled>
            * <FormattedHTMLMessage id='signUp.preNote' />
          </PanelSubtitleNoteStyled>

          {registration.activeStepIndex === 0 && (
            <>
              <RowStyled>
                <Select
                  options={parseToOptionsTranslated(
                    SUBSCRIPTION_TYPES,
                    intl,
                    'signUp.subscriptionOptions'
                  )}
                  label={intl.formatMessage({ id: 'signUp.subscriptionType' })}
                  name='subscriptionType'
                  value={registration.subscriptionType}
                  onChange={setRegistrationValue}
                  id='subscriptionType'
                  validate={validateSignup}
                  required
                />
              </RowStyled>
              <SectionTitle>
                <FormattedMessage id='signUp.companyData' />
              </SectionTitle>
              <RowStyled>
                <Select
                  options={parseToOptionsTranslated(
                    COMPANY_PROFILES,
                    intl,
                    'signUp.companyProfilesOptions'
                  )}
                  label={intl.formatMessage({ id: 'signUp.companyProfile' })}
                  name='businessProfile'
                  value={registration.businessProfile}
                  onChange={setRegistrationValue}
                  id='businessProfile'
                  validate={validateSignup}
                  required
                />
              </RowStyled>
              <RowStyled>
                <TextInput
                  required
                  label={intl.formatMessage({ id: 'common.nip' })}
                  id='taxId'
                  name='taxId'
                  placeholder={intl.formatMessage({ id: 'common.nip' })}
                  value={registration.taxId}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>
              <RowStyled>
                <TextInput
                  required
                  label={intl.formatMessage({ id: 'common.companyName' })}
                  id='companyName'
                  name='companyName'
                  placeholder={intl.formatMessage({ id: 'common.companyName' })}
                  value={registration.companyName}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>
              <RowStyled>
                <TextInput
                  label={intl.formatMessage({ id: 'common.vehiclesCount' })}
                  id='vehiclesCount'
                  name='vehiclesCount'
                  placeholder={intl.formatMessage({
                    id: 'common.vehiclesCount'
                  })}
                  value={registration.vehiclesCount}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>
              <RowStyled>
                <TextInput
                  required
                  label={intl.formatMessage({ id: 'common.address' })}
                  id='address'
                  name='address'
                  placeholder={intl.formatMessage({ id: 'common.address' })}
                  value={registration.address}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>
              <InputsRowWrapper>
                <InputWrapperStyled width={5}>
                  <TextInput
                    required
                    label={intl.formatMessage({ id: 'common.postcode' })}
                    id='postalCode'
                    name='postalCode'
                    placeholder={intl.formatMessage({ id: 'common.postcode' })}
                    value={registration.postalCode}
                    onChange={setRegistrationValue}
                    validate={validateSignup}
                  />
                </InputWrapperStyled>
                <InputWrapperStyled width={7}>
                  <TextInput
                    required
                    label={intl.formatMessage({ id: 'common.city' })}
                    id='city'
                    name='city'
                    placeholder={intl.formatMessage({ id: 'common.city' })}
                    value={registration.city}
                    onChange={setRegistrationValue}
                    validate={validateSignup}
                  />
                </InputWrapperStyled>
              </InputsRowWrapper>
              <RowStyled>
                <Select
                  options={parseToOptionsTranslated(
                    COUNTRIES,
                    intl,
                    'countries'
                  )}
                  label={intl.formatMessage({ id: 'common.country' })}
                  name='country'
                  value={registration.country}
                  onChange={setRegistrationValue}
                  id='country'
                  validate={validateSignup}
                  required
                />
              </RowStyled>
              <SectionTitle>
                {intl.formatMessage({ id: 'signUp.additionalData' })}
              </SectionTitle>
              <RowStyled>
                <TextInput
                  label={intl.formatMessage({ id: 'common.notes' })}
                  id='additionalInfo'
                  name='additionalInfo'
                  multiline
                  rows={7}
                  placeholder={intl.formatMessage({ id: 'common.notes' })}
                  value={registration.additionalInfo}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>
              <Button
                active
                onClick={() => setRegistrationValue('activeStepIndex', 1)}
              >
                {intl.formatMessage({ id: 'common.next' })}
              </Button>
            </>
          )}
          {registration.activeStepIndex === 1 && (
            <>
              <SectionTitle>
                {intl.formatMessage({ id: 'signUp.yourData' })}
              </SectionTitle>
              <RowStyled>
                <TextInput
                  required
                  label={intl.formatMessage({ id: 'common.name' })}
                  id='firstName'
                  name='firstName'
                  placeholder={intl.formatMessage({ id: 'common.name' })}
                  value={registration.firstName}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>
              <RowStyled>
                <TextInput
                  required
                  label={intl.formatMessage({ id: 'common.surname' })}
                  id='lastName'
                  name='lastName'
                  placeholder={intl.formatMessage({ id: 'common.surname' })}
                  value={registration.lastName}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>
              <RowStyled>
                <TextInput
                  required
                  label={intl.formatMessage({ id: 'common.userName' })}
                  id='username'
                  name='username'
                  placeholder={intl.formatMessage({ id: 'common.userName' })}
                  value={registration.username}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>
              <RowStyled>
                <TextInput
                  required
                  label={intl.formatMessage({ id: 'common.email' })}
                  id='email'
                  name='email'
                  placeholder={intl.formatMessage({ id: 'common.email' })}
                  value={registration.email}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                />
              </RowStyled>

              <RowStyled>
                <TextInput
                  required
                  label={intl.formatMessage({ id: 'common.phoneNumber' })}
                  id='phoneNumber'
                  name='phoneNumber'
                  placeholder={intl.formatMessage({ id: 'common.phoneNumber' })}
                  value={registration.phoneNumber}
                  onChange={setRegistrationValue}
                  validate={validateSignup}
                  helperText={intl.formatMessage({
                    id: 'forms.phoneHelperText'
                  })}
                />
              </RowStyled>
              <RowStyled>
                {/* parse options to values, no need to create enums on backend! */}
                <Select
                  options={parseToOptionsTranslated(
                    LEAD_SOURCE_OPTIONS,
                    intl,
                    'signUp.leadSourceSelectOptions'
                  )}
                  label={intl.formatMessage({ id: 'signUp.leadSourceSelect' })}
                  name='leadSourceSelect'
                  value={registration.leadSourceSelect}
                  onChange={setRegistrationValue}
                  id='leadSourceSelect'
                  validate={validateSignup}
                  required
                />
              </RowStyled>
              {leadSourceOtherDetailsVisible ? (
                <RowStyled>
                  <TextInput
                    required
                    label={intl.formatMessage({
                      id: 'signUp.leadSourceOtherDetails'
                    })}
                    id='leadSourceOtherDetails'
                    name='leadSourceOtherDetails'
                    placeholder={null}
                    value={registration.leadSourceOtherDetails}
                    onChange={handleLeadSourceOtherDetailsChange}
                    validate={validateSignup}
                  />
                </RowStyled>
              ) : null}

              {leadSourceAffiliateProgramDetailsVisible ? (
                <>
                  <RowStyled>
                    <TextInput
                      required
                      label={intl.formatMessage({
                        id:
                          'signUp.leadSourceAffiliateProgramDetailsFirstAndLastName'
                      })}
                      id='leadSourceAffiliateProgramDetailsFirstAndLastName'
                      name='leadSourceAffiliateProgramDetailsFirstAndLastName'
                      placeholder={null}
                      value={
                        registration.leadSourceAffiliateProgramDetailsFirstAndLastName
                      }
                      onChange={
                        handleLeadSourceAffiliateProgramDetailsFirstAndLastNameChange
                      }
                      validate={validateSignup}
                    />
                  </RowStyled>
                  <RowStyled>
                    <TextInput
                      required
                      label={intl.formatMessage({
                        id: 'signUp.leadSourceAffiliateProgramDetailsPhone'
                      })}
                      id='leadSourceAffiliateProgramDetailsPhone'
                      name='leadSourceAffiliateProgramDetailsPhone'
                      placeholder={null}
                      value={
                        registration.leadSourceAffiliateProgramDetailsPhone
                      }
                      onChange={
                        handleLeadSourceAffiliateProgramDetailsPhoneChange
                      }
                      validate={validateSignup}
                    />
                  </RowStyled>
                  <RowStyled>
                    <TextInput
                      required
                      multiline
                      rows={3}
                      label={intl.formatMessage({
                        id: 'signUp.leadSourceAffiliateProgramDetailsCompany'
                      })}
                      id='leadSourceAffiliateProgramDetailsCompany'
                      name='leadSourceAffiliateProgramDetailsCompany'
                      placeholder={intl.formatMessage({
                        id:
                          'signUp.leadSourceAffiliateProgramDetailsCompanyPlaceholder'
                      })}
                      value={
                        registration.leadSourceAffiliateProgramDetailsCompany
                      }
                      onChange={
                        handleLeadSourceAffiliateProgramDetailsCompanyChange
                      }
                      validate={validateSignup}
                    />
                  </RowStyled>
                </>
              ) : null}

              <RowStyled>
                <Condition>
                  <Checkbox
                    name='regulationsAccepted'
                    value={registration.regulationsAccepted}
                    onChange={name =>
                      setRegistrationValue(
                        name,
                        !registration.regulationsAccepted
                      )
                    }
                    option={{ value: BOOLEAN.YES }}
                  />
                  <span>
                    <FormattedHTMLMessage id='signUp.agree' />
                  </span>
                </Condition>
              </RowStyled>
              <Button active={valid} onClick={registerUser}>
                <FormattedMessage id='common.register' />
              </Button>
            </>
          )}
        </Column>
      </Panel>
    </div>
  )
}
const ConnectedComponent = connect(Signup)

export default styled(ConnectedComponent)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${Button} {
    width: 100%;
  }
`

const RowStyled = styled(Row)`
  margin-bottom: 1.2rem;
`

const InputWrapperStyled = styled(InputWrapper)`
  margin-bottom: 1.2rem;
`

const PanelSubtitleStyled = styled(PanelSubtitle)`
  margin-bottom: 1.2rem;
`

const PanelSubtitleNoteStyled = styled(PanelSubtitle)`
  margin-bottom: 0.8rem;
  font-size: 0.7rem;
  margin-top: 0.4rem;
`
