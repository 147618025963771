import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { propEq, propOr, find } from 'ramda'

const SelectInput = ({
  options,
  pristine,
  name,
  value,
  id,
  onChange,
  validate,
  helperText,
  label,
  multiple,
  icon,
  required
}) => {
  const classes = useStyles()
  const [inputPristine, _setPristine] = useState(pristine)
  const [{ valid, error }, _validate] = useState({ valid: true, errors: [] })

  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [inputLabel])

  const handleChange = e => onChange(name, e.target.value)

  useEffect(() => {
    if (!inputPristine || !pristine) {
      validate(name, value, v => {
        _validate(() => ({ valid: v.valid, error: v.errors.join(' ') }))
      })
    }
  }, [value, inputPristine])

  const findNameByValue = optionValue =>
    propOr('', 'name', find(propEq('value', optionValue))(options))

  return (
    <div className={classes.container}>
      <FormControl
        variant='outlined'
        className={classes.formControl}
        onBlur={() => _setPristine(false)}
        error={!valid}
      >
        <InputLabel ref={inputLabel} id={`${id}-label`}>
          {label}
          {required && <Required>*</Required>}
        </InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value}
          labelWidth={labelWidth}
          onChange={handleChange}
          multiple={multiple}
          IconComponent={MUIDropdownIcon}
          MenuProps={{ id: `${id}-select` }}
          input={
            <OutlinedInput
              labelWidth={labelWidth}
              classes={{
                root: classes.root,
                input: classes.input,
                focused: classes.focused,
                notchedOutline: classes.notchedOutline
              }}
            />
          }
          renderValue={selected => (
            <Value
              value={findNameByValue(selected)}
              classes={classes}
              icon={icon}
            />
          )}
        >
          {options.map(option => (
            <MenuItem value={option.value}>{option.name}</MenuItem>
          ))}
        </Select>
        {(helperText || error) && (
          <FormHelperText>{valid ? helperText : error}</FormHelperText>
        )}
      </FormControl>
    </div>
  )
}

SelectInput.defaultProps = {
  options: [],
  value: null,
  onChange: () => {},
  validate: () => {},
  name: '',
  id: null,
  helperText: '',
  pristine: true,
  label: '',
  multiple: false,
  icon: null
}

export default SelectInput

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  formControl: {
    display: 'flex',
    flex: 1,
    marginLeft: 0,
    marginRight: 0,
    minWidth: 120
  },
  focused: {},
  notchedOutline: {},
  root: {
    backgroundColor: 'white',
    '&$focused': {
      boxShadow: '0 2px 10px 0 rgba(19, 162, 191, 0.25)'
    },
    '& $notchedOutline': {
      borderWidth: '1px',
      borderColor: '#e2e3e5'
    },
    '&:hover $notchedOutline': {},
    '&$focused $notchedOutline': {
      borderWidth: '1px',
      borderColor: '#13a2bf'
    }
  },
  input: {
    height: '0.9rem',
    color: '#585a59'
  },
  label: {
    color: 'red',
    opacity: '0.7'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  selectedValue: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    marginRight: '10px',
    width: '20px',
    height: 'auto'
  }
}))

const Value = ({ value, classes, icon }) => (
  <div className={classes.selectedValue}>
    {icon && icon}
    <span>{value}</span>
  </div>
)

const Required = styled.span`
  color: red;
  font-size: 0.75rem;
`
