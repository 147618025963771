import React from 'react'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import Signup from 'src/features/auth/Signup'
import EuFinancingLogoLarge from 'src/components/header/components/EuFinancingLogoLarge'
import EUFinancingDialog from 'src/components/EUFinancingDialog'

export default () => (
  <ThemeProvider>
    <Layout>
      <Signup />
    </Layout>
    <EUFinancingDialog />
    <EuFinancingLogoLarge />
  </ThemeProvider>
)
