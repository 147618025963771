import React from 'react'
import styled, { css } from 'styled-components'
import MUIDoneIcon from '@material-ui/icons/Done'

const Steps = ({ className, steps, activeIndex, onStepClick }) => (
  <div className={className}>
    <InnerWrapper done={activeIndex === steps.length - 1}>
      {steps.map((step, index) => (
        <StepStyled
          key={index}
          done={index < activeIndex}
          active={index === activeIndex}
          number={index + 1}
          name={step}
          onClick={() => onStepClick(index)}
        />
      ))}
    </InnerWrapper>
  </div>
)

export default styled(Steps)`
  display: flex;
  width: 250px;
  position: relative;
  margin: 0.6rem auto 1.8rem auto;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  &:after {
    content: '';
    display: block;
    width: 75%;
    height: 2px;
    position: absolute;
    top: 30%;
    left: 12%;
    background-color: ${({ theme, done }) =>
      done ? theme.colors.apple.hex() : theme.colors.mercury.hex()};
  }
`

const Step = ({ className, number, name, active, done, ...rest }) => (
  <div className={className} {...rest}>
    <Circle active={active} done={done}>
      {done ? <MUIDoneIconStyled /> : <span>{number}</span>}
    </Circle>
    <StepDescription active={active}>{name}</StepDescription>
  </div>
)

const StepStyled = styled(Step)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const MUIDoneIconStyled = styled(MUIDoneIcon)`
  font-size: 20px !important;
`

const Circle = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.apple.hex()};
  color: ${({ theme }) => theme.colors.apple.hex()};
  background-color: white;
  font-weight: 600;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  z-index: 2;
  & > span {
    display: inline-block;
    position: relative;
    z-index: 10;
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.apple.hex()};
      border: 2px solid rgba(70, 167, 65, 0.4);
      color: white;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        background-color: rgba(70, 167, 65, 0.4);
        box-shadow: 0 2px 10px 0 rgba(70, 167, 65, 0.25);
        width: 34px;
        height: 34px;
        border-radius: 50%;
        z-index: 1;
      }
    `}
  ${({ done }) =>
    done &&
    css`
      background-color: ${({ theme }) => theme.colors.apple.hex()};
      color: white;
    `}
`

const StepDescription = styled.span`
  display: flex;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  white-space: nowrap;
  opacity: 0.7;
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.apple.hex()};
      font-weight: 600;
      opacity: 1;
    `}
`
